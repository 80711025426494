import React, { useState, useEffect, useRef } from 'react';

const TypeWriter = ({ contentSegments, onComplete, skipAnimation }) => {
  const [visibleSegments, setVisibleSegments] = useState([]);
  const timersRef = useRef([]);
  
  useEffect(() => {
    // スキップが押された場合はすべてのセグメントを即時表示
    if (skipAnimation) {
      setVisibleSegments(new Array(contentSegments.length).fill(true));
      // 少し遅延させてからonCompleteを呼び出し
      const completeTimer = setTimeout(() => onComplete(), 100);
      timersRef.current.push(completeTimer);
      return;
    }
    
    // すべてのセグメントを非表示に初期化
    setVisibleSegments(new Array(contentSegments.length).fill(false));
    
    // 各セグメントのタイプを判定（デバッグ用）
    const segmentTypes = contentSegments.map(segment => {
      if (segment.includes('student-segment')) {
        return 'student';
      } else if (segment.includes('teacher-segment')) {
        return 'teacher';
      } else {
        return 'other';
      }
    });
    
    console.log('セグメントタイプ:', segmentTypes);
    
    // 前回のタイマーをクリア
    timersRef.current.forEach(timer => clearTimeout(timer));
    timersRef.current = [];
    
    // 表示間隔の設定（ミリ秒）
    const displayDelay = 1000; // セグメント間の表示間隔
    const initialDelay = 500;  // 最初のセグメント表示までの遅延
    
    // 各セグメントを順番に表示
    contentSegments.forEach((_, index) => {
      const timer = setTimeout(() => {
        setVisibleSegments(prev => {
          const updated = [...prev];
          updated[index] = true;
          return updated;
        });
        
        // 最後のセグメントが表示されたらonCompleteを呼び出す
        if (index === contentSegments.length - 1) {
          const completeTimer = setTimeout(() => onComplete(), 500);
          timersRef.current.push(completeTimer);
        }
      }, initialDelay + (displayDelay * index));
      
      timersRef.current.push(timer);
    });
    
    // セグメントが0の場合の対応
    if (contentSegments.length === 0) {
      const completeTimer = setTimeout(() => onComplete(), initialDelay);
      timersRef.current.push(completeTimer);
    }
    
    // クリーンアップ関数
    return () => {
      timersRef.current.forEach(timer => clearTimeout(timer));
    };
  }, [contentSegments, onComplete, skipAnimation]);

  return (
    <div>
      {contentSegments.map((segment, index) => (
        <div 
          key={index} 
          style={{ 
            opacity: visibleSegments[index] ? 1 : 0,
            transform: visibleSegments[index] ? 'translateY(0)' : 'translateY(1px)', // 下から上へのアニメーション
            maxHeight: visibleSegments[index] ? '1000px' : '0',
            overflow: 'hidden',
            marginBottom: visibleSegments[index] ? '8px' : '0',
            transition: 'opacity 0.5s ease, transform 0.5s ease, max-height 0.5s ease, margin-bottom 0.5s ease'
          }}
          dangerouslySetInnerHTML={{ __html: segment }}
        />
      ))}
    </div>
  );
};

export default TypeWriter;