import React, { useState } from 'react';
import './App.css';
import { API_URL } from './config';

const EvaluationForm = ({ searchHistoryId }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [showComment, setShowComment] = useState(false);
  const [isRatingSubmitted, setIsRatingSubmitted] = useState(false);

  const handleRatingClick = async (newRating) => {
    if (isRatingSubmitted) return;

    try {
      const response = await fetch(`${API_URL}/evaluate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          search_history_id: searchHistoryId,
          rating: newRating,
          comment: ''
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(`Network response was not ok: ${errorData.detail || response.statusText}`);
      }

      setRating(newRating);
      setIsRatingSubmitted(true);
    } catch (error) {
      console.error('評価の送信エラー:', error);
      alert('評価の送信に失敗しました。');
    }
  };

  const submitComment = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/evaluate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          search_history_id: searchHistoryId,
          rating: rating || 3,
          comment: comment
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(`Network response was not ok: ${errorData.detail || response.statusText}`);
      }

      setComment('');
      setShowComment(false);
    } catch (error) {
      console.error('コメントの送信エラー:', error);
      alert('コメントの送信に失敗しました。');
    }
  };

  return (
    <div className="review-container">
      <div className="review-header">
        <p className="review-title">タップして回答を評価</p>
        <div className="rating-comment-container">
          <div className={`stars-span ${isRatingSubmitted ? 'disabled' : ''}`}>
            {[5, 4, 3, 2, 1].map((value) => (
              <React.Fragment key={value}>
                <input
                  id={`review${value.toString().padStart(2, '0')}`}
                  type="radio"
                  name="review"
                  className="star-input"
                  checked={rating === value}
                  onChange={() => {}}
                  disabled={isRatingSubmitted}
                />
                <label
                  htmlFor={`review${value.toString().padStart(2, '0')}`}
                  className={`star-label ${isRatingSubmitted ? 'disabled' : ''} ${rating >= value ? 'checked' : ''}`}
                  onClick={() => handleRatingClick(value)}
                >
                  ★
                </label>
              </React.Fragment>
            ))}
          </div>
          <button
            type="button"
            onClick={() => setShowComment(!showComment)}
            className="comment-icon-button"
            aria-label={showComment ? "コメントを隠す" : "コメントを追加"}
          >
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
            </svg>
          </button>
        </div>
      </div>

      {showComment && (
        <form onSubmit={submitComment} className="comment-form">
          <div>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="コメントを入力してください..."
              className="comment-textarea"
              rows="3"
            />
          </div>
          <div className="submit-button-container">
            <button
              type="submit"
              disabled={!comment.trim()}
              className="submit-button"
            >
              コメントを送信
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EvaluationForm;
