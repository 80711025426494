import React, { useState, useEffect } from 'react';
import './App.css'; // 既存のCSSファイルを使用

const LoadingProgressBar = ({ progress = 0, indeterminate = true }) => {
  const [currentProgress, setCurrentProgress] = useState(0);
  
  // インターミネイトモード用のアニメーション
  useEffect(() => {
    if (indeterminate) {
      // プログレスが指定されていない場合は自動的にアニメーションさせる
      let interval;
      
      // アニメーションの初期化
      interval = setInterval(() => {
        setCurrentProgress(prev => {
          // 0-100の間で往復するようなアニメーション
          if (prev >= 90) {
            return 30; // 90%まで行ったら30%に戻る
          }
          return prev + 2; // 徐々に増加
        });
      }, 100);
      
      return () => clearInterval(interval);
    } else {
      // 明示的なプログレス値が提供された場合
      setCurrentProgress(progress);
    }
  }, [indeterminate, progress]);

  return (
    <div className="loading-progress-container">
      <div className="loading-progress-label">
        {indeterminate ? "読み込み中..." : `${Math.round(currentProgress)}%`}
      </div>
      <div className="loading-progress-track">
        <div 
          className={`loading-progress-bar ${indeterminate ? 'indeterminate' : ''}`}
          style={{ width: `${currentProgress}%` }}
        />
      </div>
    </div>
  );
};

export default LoadingProgressBar;