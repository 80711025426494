import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import EvaluationForm from './EvaluationForm';
import MessageDisplay from './MessageDisplay';
import LoadingProgressBar from './LoadingProgressBar';
import './App.css';
import { API_URL } from './config';

function App() {
    const [response, setResponse] = useState("");
    const [relatedWords, setRelatedWords] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const responseRef = useRef(null);
    const [conversationId, setConversationId] = useState("");
    const [searchHistoryId, setSearchHistoryId] = useState(null);
    const [resetQuery, setResetQuery] = useState("");
    const [lastResponse, setLastResponse] = useState("");
    const [isSearchResult, setIsSearchResult] = useState(false);
    const [searchResponse, setSearchResponse] = useState("");
    const [currentQueryType, setCurrentQueryType] = useState("normal");
    const [clickedWord, setClickedWord] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isAnimationComplete, setIsAnimationComplete] = useState(false);
    // ユーザートラッキング用の状態変数を追加
    const [userUuid, setUserUuid] = useState("");
    // 新しい状態変数（MessageDisplayと共有）
    const [skipAnimation, setSkipAnimation] = useState(false);
    // プログレスバー用の状態変数を追加
    const [loadingProgress, setLoadingProgress] = useState(0);
    // ユーザーメッセージ表示用の状態変数を追加
    const [showUserMessage, setShowUserMessage] = useState(false);
    // ユーザーメッセージ表示内容を保持する状態変数
    const [userMessageContent, setUserMessageContent] = useState("");

    // テキストエリアの高さ自動調整関数 - 上方向に拡張
    const autoResizeTextarea = (e) => {
      const textarea = e.target;
      
      // スタイルをリセットして正確な高さを計算
      textarea.style.height = '54px'; // 最小高さにリセット
      
      // 必要な高さを計算
      const scrollHeight = textarea.scrollHeight;
      
      if (scrollHeight > 54) {
        textarea.style.height = `${scrollHeight}px`;
        
        // 一定の高さを超えたらスクロール可能に
        if (scrollHeight > 150) {
          textarea.style.overflowY = 'auto';
          textarea.style.height = '150px'; // 最大高さを制限
        } else {
          textarea.style.overflowY = 'hidden';
        }
      }
    };

    // テキストエリアのキーダウンイベントハンドラ
    const handleKeyDown = (e) => {
      // モバイル用入力欄かどうかを判定
      const isMobileInput = e.target.closest('.input-container-mobile') !== null;
      
      // Enterが押されたとき
      if (e.key === 'Enter') {
        // IME入力中（変換中）でない場合のみ処理
        if (!e.isComposing && !e.nativeEvent.isComposing) {
          // モバイル用入力欄の場合は常に改行を許可（送信しない）
          if (isMobileInput) {
            // 通常の改行動作を許可（何もしない）
            return;
          } 
          // PC用入力欄の場合は従来通りの挙動（Shiftなしで送信）
          else if (!e.shiftKey) {
            e.preventDefault(); // デフォルトの改行を防止
            if (resetQuery.trim()) {
              handleReset();
            }
          }
        }
      }
    };

    // コンポーネントマウント時にCookieからユーザーIDを取得
    useEffect(() => {
        // Cookieからユーザーuuidを取得する関数
        const getUserUuidFromCookie = () => {
            const cookies = document.cookie.split(';');
            for (let cookie of cookies) {
                const [name, value] = cookie.trim().split('=');
                if (name === 'user_uuid') {
                    return value;
                }
            }
            return "";
        };
        
        const uuid = getUserUuidFromCookie();
        if (uuid) {
            setUserUuid(uuid);
            // ユーザー統計情報を取得
            fetchUserStats(uuid);
        }
    }, []);

    // ユーザー統計情報を取得する関数
    const fetchUserStats = async (uuid) => {
        try {
            // responseは使用していないので変数定義を削除
            await axios.get(`${API_URL}/api/user-stats`, {
                withCredentials: true  // Cookieを送信するために必要
            });
            // 検索回数の処理は削除済み
        } catch (error) {
            console.error("ユーザー統計情報の取得に失敗:", error);
        }
    };

// まず performApiRequest を useCallback でラップ - API_URLを依存配列から削除
const performApiRequest = useCallback(async (type, queryToSend, currentConversationId) => {
  try {
      // デバッグログを追加 - リクエスト前
      console.log('API リクエスト送信:', {
          query: queryToSend ? queryToSend.substring(0, 50) + (queryToSend.length > 50 ? '...' : '') : '',
          type,
          conversation_id: currentConversationId,
          user_uuid: userUuid
      });

      // リクエストにユーザーUUIDを含める
      const response = await axios.post(`${API_URL}/ask-gemini`, {
          query: queryToSend,
          type,
          conversation_id: currentConversationId,
          user_uuid: userUuid  // ユーザーUUIDを送信
      }, {
          withCredentials: true,  // Cookieを送信するために必要
          onUploadProgress: progressEvent => {
            if (progressEvent.total) {
                const percentCompleted = Math.round((progressEvent.loaded * 50) / progressEvent.total); // リクエスト中は最大50%まで
                setLoadingProgress(percentCompleted);
            }
          },
          onDownloadProgress: progressEvent => {
              if (progressEvent.total) {
                  const percentCompleted = 50 + Math.round((progressEvent.loaded * 50) / progressEvent.total); // レスポンス中は50%〜100%
                  setLoadingProgress(percentCompleted);
              } else {
                  // totalが取得できない場合は徐々に進める
                  setLoadingProgress(prev => Math.min(95, prev + 5));
              }
          }
      });

      setLoadingProgress(100);

      // デバッグログを追加 - レスポンス受信後
      console.log('API レスポンス受信:', {
        status: response.status,
        data_keys: Object.keys(response.data),
        html_length: response.data.html ? response.data.html.length : 0,
        has_related_words: Array.isArray(response.data.related_words) && response.data.related_words.length > 0
      });

      setResponse(response.data.html);
      if (response.data.raw_text) {
          setLastResponse(response.data.raw_text);
      }
      
      if (response.data.is_search_result) {
          setIsSearchResult(true);
          setSearchResponse(response.data.search_response);
      } else {
          setIsSearchResult(false);
      }
      
      // APIからのレスポンスでユーザー情報を更新
      if (response.data.user_uuid) {
          setUserUuid(response.data.user_uuid);
      }
      
      setRelatedWords(response.data.related_words || []);
      setKeywords(response.data.keywords || []);
      setConversationId(response.data.conversation_id);
      setSearchHistoryId(response.data.search_history_id);
  } catch (error) {

    console.error("API エラー詳細:", {
      message: error.message,
      response: error.response ? {
          status: error.response.status,
          data: error.response.data
      } : 'No response'
    });

      console.error("Error:", error);
      setResponse("エラーが発生しました。");
  } finally {
      setTimeout(() => {
          setIsLoading(false);
      }, 500); // 少し遅延させて100%の表示を見せる
  }
}, [userUuid]);

// 次に sendQuery を定義
const sendQuery = useCallback(async (type = "normal", inputQuery = null, newConversation = false) => {
  // ユーザーメッセージ内容を設定
  if (type === "reset" && inputQuery) {
      setUserMessageContent(inputQuery);
  } else if (type === "normal" && resetQuery) {
      setUserMessageContent(resetQuery);
  } else if (type === "detailed") {
      setUserMessageContent("詳しく");
  } else if (type === "regenerate") {
      setUserMessageContent("再回答");
  }
  
  // ユーザーメッセージを表示（APIロード前）
  setShowUserMessage(true);
  
  // クエリタイプを設定
  setCurrentQueryType(type);
  
  // クリックされたワードを設定（resetタイプの場合）
  if (type === "reset" && inputQuery) {
      setClickedWord(inputQuery);
  }
  
  // 少し遅延させてからローディングを開始
  setTimeout(() => {
      setIsLoading(true);
      setIsAnimationComplete(false); // 新しいクエリが送信されたらアニメーション完了状態をリセット
      setSkipAnimation(false); // アニメーションスキップ状態もリセット
      setLoadingProgress(0); // 進捗状態の初期化
      
      console.log('sendQuery called with:');
      console.log('type:', type);
      console.log('inputQuery:', inputQuery);
      console.log('currentQueryType:', currentQueryType);
      
      let queryToSend = "";
      
      if (isSearchResult && type === "normal") {
          queryToSend = `前の回答：${searchResponse}\n\n新しい質問：${inputQuery}`;
      } else {
          if (type === "normal" && conversationId) {
              queryToSend = inputQuery;
          } else if (type === "detailed" || type === "regenerate") {
              queryToSend = lastResponse;
              newConversation = true;
          } else if (inputQuery !== null) {
              queryToSend = inputQuery;
          } else {
              queryToSend = inputQuery;
          }
      }

      let currentConversationId = conversationId;
      if (newConversation) {
          currentConversationId = "";
      }
      
      // APIリクエスト送信（非同期）
      performApiRequest(type, queryToSend, currentConversationId);
  }, 100); // 100msの遅延でユーザーメッセージが確実に表示されるようにする
}, [conversationId, isSearchResult, searchResponse, lastResponse, currentQueryType, performApiRequest, resetQuery]);

    useEffect(() => {
        if (responseRef.current) {
            responseRef.current.scrollTop = responseRef.current.scrollHeight;
        }
    }, [response]);

const handleReset = useCallback(() => {
  if (resetQuery.trim()) {
    // 送信前に画面の最上部にスクロール
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    sendQuery("reset", resetQuery, true);
    setResetQuery(""); // 入力内容をクリア
    
    // 以下の処理を追加：全てのテキストエリアの高さをリセット
    const textareas = document.querySelectorAll('textarea.chat-input');
    textareas.forEach(textarea => {
      textarea.style.height = '54px'; // 初期高さに戻す
      textarea.style.overflowY = 'hidden';
    });
  }
}, [resetQuery, sendQuery]);

    const handleKeywordClick = useCallback((word) => {
        sendQuery("reset", word, true);
    }, [sendQuery]);  
    
    // アニメーション完了を処理するハンドラー
    const handleAnimationComplete = useCallback(() => {
        setIsAnimationComplete(true);
    }, []);
    
    // スキップボタンのクリックハンドラー
    const handleSkip = useCallback(() => {
        setSkipAnimation(true);
        setIsAnimationComplete(true);
    }, []);
    
    // ヘッダーロゴコンテナのクリックハンドラー - 初期表示に戻す
    const handleLogoClick = useCallback(() => {
        // 状態をリセット
        setResponse("");
        setRelatedWords([]);
        setKeywords([]);
        setConversationId("");
        setSearchHistoryId(null);
        setLastResponse("");
        setIsSearchResult(false);
        setSearchResponse("");
        setResetQuery("");
        setClickedWord("");
        setIsLoading(false);
        setIsAnimationComplete(false);
        setSkipAnimation(false);
        setShowUserMessage(false); // ユーザーメッセージ表示状態もリセット
        setUserMessageContent(""); // ユーザーメッセージ内容もリセット
    }, []);
    
    // ユーザーメッセージ表示用コンポーネント
    const UserMessageDisplay = () => {
        if (!showUserMessage || !userMessageContent.trim()) {
            return null;
        }
        
        return (
            <div className="header-user-message">
                {userMessageContent}
            </div>
        );
    };
    
    return (
      <div>
        {!isLoading && !response ? (
          // 初期表示
          <div className="chat-container">
            <div className="chat-layout" style={{ height: '100vh' }}>
              <div className="chat-initial-content">
                <div className="title-section">
                  {/* ロゴとタイトルを並べて表示 */}
                  <div className="logo-title-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src="/images/Demi.png" alt="DemiChat Logo" className="chat-logo" />
                    <h1 className="chat-title">
                      <span className="title-first-line">Hi, I'm</span>
                      <span className="title-second-line">DemiChat</span>
                      <span className="sr-only">- あなたの知りたいを解決する対話型AI</span>
                    </h1>                  
                  </div>
                  <p className="chat-subtitle">知りたい言葉はありますか？</p>
                </div>
                {/* PC用入力欄 */}
                <div className="input-container-pc">
                  <div className="input-wrapper">
                    <textarea
                      value={resetQuery}
                      onChange={(e) => setResetQuery(e.target.value)}
                      onKeyDown={handleKeyDown}
                      placeholder="疑問を投げかけよう..."
                      className="chat-input"
                      rows="1"
                      onInput={autoResizeTextarea}
                    />
                    <button
                      onClick={handleReset}
                      className={`send-button ${resetQuery.trim() ? 'send-button-active' : ''}`}
                      aria-label="送信"
                    >
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 3L8 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                        <path d="M4 7L8 3L12 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </button>
                  </div>
                </div>

                {/* 追加: モバイル版の入力欄 */}
                <div className="input-container-mobile">
                  <div className="input-wrapper">
                    <textarea
                      value={resetQuery}
                      onChange={(e) => setResetQuery(e.target.value)}
                      onKeyDown={handleKeyDown}
                      placeholder="疑問を投げかけよう..."
                      className="chat-input"
                      rows="1"
                      onInput={autoResizeTextarea}
                    />
                    <button
                      onClick={handleReset}
                      className={`send-button ${resetQuery.trim() ? 'send-button-active' : ''}`}
                      aria-label="送信"
                    >
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 3L8 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                        <path d="M4 7L8 3L12 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // 出力開始後のレイアウト
          <div>
            <header className="app-header">
            <div 
              className="header-logo-container" 
              onClick={handleLogoClick}
              style={{ cursor: 'pointer' }}
            >
              <img src="/images/Demi.png" alt="DemiChat Logo" className="header-logo" />
              <span className="sr-only">DemiChat - あなたの知りたいを解決する対話型AI</span>
            </div>
              
              {/* ヘッダー中央にユーザーメッセージ表示エリアを追加 */}
              <UserMessageDisplay />
              
              {/* ヘッダー右側にスキップボタンを追加 - アニメーション中のみ表示 */}
              {(!isLoading && response && !isAnimationComplete) && (
                <div className="header-skip-button-container">
                  <button 
                    className="skip-button" 
                    onClick={handleSkip}
                  >
                    スキップ
                  </button>
                </div>
              )}
            </header>
            <div className="chat-container">
              {isLoading ? (
                <>
                  <LoadingProgressBar progress={loadingProgress} indeterminate={loadingProgress === 0} />
                </>
              ) : (
                <>
                  <div className="response-area" ref={responseRef}>
                    <MessageDisplay 
                      html={response} 
                      queryType={currentQueryType}
                      clickedWord={clickedWord}
                      resetQuery={resetQuery}
                      onAnimationComplete={handleAnimationComplete}
                      skipAnimation={skipAnimation}
                      hideUserMessage={true} // ユーザーメッセージを非表示にする
                    />
                  </div>
                  
                  {/* アニメーション完了後にのみ表示 */}
                  {isAnimationComplete && (
                    <div className="post-animation-content">
                      {response && lastResponse && (
                        <div className="action-buttons fade-in">
                          <button
                            className="action-button"
                            onClick={() => sendQuery("detailed")}
                          >
                            🚀 詳しく
                          </button>
                          <button
                            className="action-button"
                            onClick={() => sendQuery("regenerate")}
                          >
                            🔄 再回答
                          </button>
                        </div>
                      )}

                      {relatedWords.length > 0 && (
                        <div className="related-words-section fade-in" style={{ animationDelay: '0.2s' }}>
                          <h3>関連ワード</h3>
                          <div className="word-buttons">
                            {relatedWords.map((word, index) => (
                              <button
                                className="word-button"
                                key={word + index}
                                onClick={() => sendQuery("reset", word, true)}
                                style={{ animationDelay: `${0.3 + index * 0.05}s` }}
                              >
                                {word}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}

                      {keywords.length > 0 && (
                        <div className="keywords-section fade-in" style={{ animationDelay: '0.4s' }}>
                          <h3>キーワード</h3>
                          <div className="word-buttons">
                            {keywords.map((keyword, index) => (
                              <button
                                className="word-button"
                                key={keyword + index}
                                onClick={() => handleKeywordClick(keyword)}
                                style={{ animationDelay: `${0.5 + index * 0.05}s` }}
                              >
                                {keyword}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                      
                      {response && 
                        <div className="evaluation-form-container fade-in" style={{ animationDelay: '0.6s' }}>
                          <EvaluationForm 
                            searchHistoryId={searchHistoryId} 
                            userUuid={userUuid} // ユーザーUUIDを渡す
                          />
                        </div>
                      }
                      <input type="hidden" id="searchHistoryId" value={searchHistoryId || ''} />
                      <input type="hidden" id="userUuid" value={userUuid || ''} />
                      <div className="input-container-pc">
                        <div className="input-wrapper">
                          <textarea
                            value={resetQuery}
                            onChange={(e) => setResetQuery(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="疑問を投げかけよう..."
                            className="chat-input"
                            rows="1"
                            onInput={autoResizeTextarea}
                          />
                          <button
                            onClick={handleReset}
                            className={`send-button ${resetQuery.trim() ? 'send-button-active' : ''}`}
                            aria-label="送信"
                          >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8 3L8 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                              <path d="M4 7L8 3L12 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        {/* モバイル用入力欄も同様に */}
        <div className="input-container-mobile">
          <div className="input-wrapper">
            <textarea
              value={resetQuery}
              onChange={(e) => setResetQuery(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="疑問を投げかけよう..."
              className="chat-input"
              rows="1"
              onInput={autoResizeTextarea}
            />
            <button
              onClick={handleReset}
              className={`send-button ${resetQuery.trim() ? 'send-button-active' : ''}`}
              aria-label="送信"
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 3L8 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M4 7L8 3L12 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
}

export default App;