import React, { useState, useEffect, useCallback } from 'react';
import TypeWriter from './TypeWriter';

const MessageDisplay = ({ 
    html, 
    queryType, 
    clickedWord, 
    resetQuery, 
    onAnimationComplete, 
    skipAnimation,
    showUserMessage = true,
    showOnlyUserMessage = false,  // ローディング中にユーザーメッセージだけ表示する場合のフラグ
    hideUserMessage = false  // ユーザーメッセージを非表示にするための新しいフラグ
}) => {
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    // 外部からskipAnimationプロパティを受け取るように変更

    const getQueryDisplay = (type, query, word) => {
        switch (type) {
            case 'detailed':
                return '詳しく';
            case 'regenerate':
                return '再回答';
            case 'reset':
                return word;
            case 'normal':
            default:
                return query;
        }
    };

    const processTextIntoSegments = useCallback((text, startCheckpoint) => {
        let segments = [];
        let currentSegment = '';
        let insideQuotes = false;
        
        let className = '';
        if (startCheckpoint === '👩‍🏫') {
            className = 'text-segment teacher-segment';
        } else if (startCheckpoint === '👨‍🎓') {
            className = 'text-segment student-segment';
        }
        
        for (let i = 0; i < text.length; i++) {
            const char = text[i];
            currentSegment += char;
            
            if (char === '「') {
                insideQuotes = true;
            } else if (char === '」') {
                insideQuotes = false;
            }
            
            if (!insideQuotes && (char === '。' || char === '！' || char === '？')) {
                if (currentSegment.trim()) {
                    segments.push(`<div class="${className}">${currentSegment}</div>`);
                }
                currentSegment = '';
            }
        }
        
        if (currentSegment.trim()) {
            segments.push(`<div class="${className}">${currentSegment}</div>`);
        }
        
        return segments;
    }, []);

    const splitIntoSets = useCallback((text) => {
        const checkpoints = ['👨‍🎓', '👩‍🏫', '✅'];
        const sets = [];
        const checkpointIndices = [];
        
        checkpoints.forEach(checkpoint => {
            let index = -1;
            while ((index = text.indexOf(checkpoint, index + 1)) !== -1) {
                checkpointIndices.push({ index, checkpoint });
            }
        });
        
        checkpointIndices.sort((a, b) => a.index - b.index);
        
        for (let i = 0; i < checkpointIndices.length; i++) {
            const startIndex = checkpointIndices[i].index;
            const endIndex = i + 1 < checkpointIndices.length 
                ? checkpointIndices[i + 1].index 
                : text.length;
                
            let setContent = text.slice(startIndex, endIndex);
            const startCheckpoint = checkpointIndices[i].checkpoint;
            
            if (startCheckpoint === '👨‍🎓' || startCheckpoint === '👩‍🏫') {
                setContent = setContent.replace(startCheckpoint, '');
            }
            
            // 各セグメントに分割
            const segments = processTextIntoSegments(setContent.trim(), startCheckpoint);
            
            // 空のセグメント配列の場合はスキップ
            if (segments.length === 0) continue;
            
            sets.push({
                segments: segments,
                startCheckpoint: startCheckpoint,
                isLastSet: i === checkpointIndices.length - 1
            });
        }
        
        return sets;
    }, [processTextIntoSegments]);

    const getImageForCheckpoint = (checkpoint) => {
        switch (checkpoint) {
            case '👩‍🏫':
                return "/images/teacher.png";
            case '👨‍🎓':
                return "/images/student.png";
            default:
                return null;
        }
    };

    const getClassNameForSet = (currentSet, previousSet) => {
        let className = 'chat-message-set';

        if (previousSet && (
            previousSet.startCheckpoint === '✅' ||
            currentSet.startCheckpoint === '✅'
        )) {
            className += ' no-gap';
        }

        if (currentSet.isLastSet && previousSet) {
            className += ' no-gap';
        }

        return className;
    };

    // useEffectを追加してskipAnimationの変更を監視
    useEffect(() => {
        if (skipAnimation) {
            // すべてのアニメーションをスキップして最後のメッセージまでジャンプ
            const messageSets = splitIntoSets(html);
            setCurrentMessageIndex(messageSets.length);
            
            // アニメーション完了を通知
            if (onAnimationComplete) {
                onAnimationComplete();
            }
        }
    }, [skipAnimation, html, onAnimationComplete, splitIntoSets]);

    const renderMessageContent = (segments, index, isLastMessageSet) => {
        // セグメントが空の場合は何も表示しない
        if (!segments || segments.length === 0) {
            return null;
        }
        
        // 現在のインデックスより前のメッセージは即時表示
        if (index < currentMessageIndex) {
            return (
                <div className="message-content">
                    {segments.map((segment, i) => {
                        // 空のセグメントはスキップ
                        if (!segment.trim() || segment.includes('class="text-segment student-segment"></div>')) {
                            return null;
                        }
                        return <div key={i} dangerouslySetInnerHTML={{ __html: segment }} />;
                    })}
                </div>
            );
        }
        
        // 現在のインデックスのメッセージのみアニメーション表示
        if (index === currentMessageIndex) {
            // 空のセグメントを除外
            const nonEmptySegments = segments.filter(segment => 
                segment.trim() && !segment.includes('class="text-segment student-segment"></div>')
            );
            
            if (nonEmptySegments.length === 0) {
                // すべてのセグメントが空の場合は次のメッセージへ
                setTimeout(() => {
                    if (isLastMessageSet) {
                        if (onAnimationComplete) {
                            onAnimationComplete();
                        }
                    }
                    setCurrentMessageIndex(prev => prev + 1);
                }, 0);
                return null;
            }
            
            return (
                <div className="message-content">
                    <TypeWriter 
                        contentSegments={nonEmptySegments} 
                        onComplete={() => {
                            if (isLastMessageSet) {
                                // 最後のメッセージセットのアニメーションが完了したらコールバックを呼び出す
                                if (onAnimationComplete) {
                                    onAnimationComplete();
                                }
                            }
                            setCurrentMessageIndex(prev => prev + 1);
                        }}
                        skipAnimation={skipAnimation}
                    />
                </div>
            );
        }
        
        // まだ表示すべきでないメッセージは非表示
        return null;
    };

    const renderMessageSets = (html) => {
        const messageSets = splitIntoSets(html);
        // スキップボタンの表示制御は削除（App.jsに移動）

        return (
            <>
                {messageSets.map((set, index) => {
                    const imagePath = getImageForCheckpoint(set.startCheckpoint);
                    const previousSet = index > 0 ? messageSets[index - 1] : null;
                    const className = getClassNameForSet(set, previousSet);
                    
                    // 現在のインデックスに基づいて表示
                    if (index > currentMessageIndex && !skipAnimation) {
                        return null;
                    }

                    // セグメントが空の場合はレンダリングしない
                    if (!set.segments || set.segments.length === 0) {
                        return null;
                    }

                    // 空のセグメントのみかチェック
                    const hasOnlyEmptySegments = set.segments.every(segment => 
                        !segment.trim() || segment.includes('class="text-segment student-segment"></div>')
                    );
                    
                    if (hasOnlyEmptySegments) {
                        return null;
                    }

                    // 最後のメッセージセットかどうかを確認
                    const isLastMessageSet = index === messageSets.length - 1;

                    return (
                        <div key={index} className={className}>
                            <div className="message-container">
                                {imagePath && (
                                    <div className="message-icon">
                                        <img 
                                            src={imagePath} 
                                            alt="chat icon" 
                                            className="chat-avatar" 
                                        />
                                    </div>
                                )}
                                {renderMessageContent(set.segments, index, isLastMessageSet)}
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    const renderUserMessage = (type, query, word) => {
        const content = getQueryDisplay(type, query, word);
        
        // 内容が空の場合はレンダリングしない
        if (!content || content.trim() === '') {
            return null;
        }
        
        return (
            <div className="chat-message-set user-message">
                <div className="message-container">
                    <div className="message-content">
                        <div className="text-segment user-segment">
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const shouldDisplayQuery = () => {
        // 内容が空の場合は表示しない
        if (queryType === 'reset' && (!clickedWord || clickedWord.trim() === '')) {
            return false;
        }
        if (queryType === 'normal' && (!resetQuery || resetQuery.trim() === '')) {
            return false;
        }
        
        if (queryType === 'detailed' || queryType === 'regenerate') {
            return true;
        }
        if (queryType === 'reset' && clickedWord) {
            return true;
        }
        if (queryType === 'normal' && resetQuery) {
            return true;
        }
        return false;
    };

    // showOnlyUserMessage が true の場合はユーザーメッセージのみを表示
    if (showOnlyUserMessage) {
        return (
            <div className="chat-messages">
                {shouldDisplayQuery() && showUserMessage && renderUserMessage(queryType, resetQuery, clickedWord)}
            </div>
        );
    }

    return (
        <div className="chat-messages">
            {shouldDisplayQuery() && showUserMessage && !hideUserMessage && renderUserMessage(queryType, resetQuery, clickedWord)}
            {renderMessageSets(html)}
        </div>
    );
};

export default MessageDisplay;